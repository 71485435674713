import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Error from "./Pages/Error";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import UserVerification from "./Pages/UserVerification";
import User from "./Pages/User";
import UserProfile from "./Pages/UserProfile";
import ProtectedRoutes from "./Components/Layout/Protectedroutes";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/otp-varification" element={<OtpVarification />}></Route>

          <Route element={<ProtectedRoutes />}>
            <Route path="/error" element={<Error />}></Route>
            <Route
              path="/under-maintenance"
              element={<UnderMaintenance />}
            ></Route>
            <Route path="/user" element={<User />}></Route>
            <Route path="/my-profile" element={<MyProfile />}></Route>
            <Route
              path="/user-verification"
              element={<UserVerification />}
            ></Route>
            <Route path="/user-profile" element={<UserProfile />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
