export const sidebar = {
    Analytics: "Analyse",
    PAGES: "Pages",
    Users: "Utilisateurs",
    UserVerification: "Vérification des utilisateurs",
    Support: "Support",
    Notifications: "Notifications"
  };
  
  export const banner = {
    TotalUsers: "Utilisateur total",
    PaidUsers: "Utilisateurs payants",
    Lastweekanalytics: "Analyse de la semaine dernière",
    ActiveUsers: "Utilisateurs actifs",
    InactiveUsers: "Utilisateurs inactifs"
  };
  



  export const userDetails = {
    USER: "UTILISATEUR",
    AGE: "ÂGE",
    BIBLEVERSION: "VERSION DE LA BIBLE",
    DENOMINATION: "DÉNOMINATION",
    ACTION: "ACTION"
  };


  export const userProfile = {
    MyProfile: "Mon Profil",
    Settings: "Paramètres",
    FAQ: "FAQ"
  };



  

  
  export const loginWords = {
    WelcomeMessage: "Bienvenue sur SmartBible ! 👋🏻",
    SignInPrompt: "Veuillez vous connecter à votre compte et commencer l'aventure",
    Email: "Email",
    Password: "Mot de passe",
    Login: "Se connecter"
  };
  