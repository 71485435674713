import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponet = ({ currentPage, totalCount, limit, onPageChange }) => {

  const pageItems = [];
  for (let number = 1; number <= limit; number++) {
    pageItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => onPageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      {/* <Pagination.First
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
      /> */}
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {pageItems}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === limit}
      />
      {/* <Pagination.Last
        onClick={() => onPageChange(limit)}
        disabled={currentPage === limit}
      /> */}
    </Pagination>
  );
};

export default PaginationComponet;

